"use strict";

$(document).ready(function () {
  var header = $('#header');
  var respond = $('#respond');
  var scrollTo = $('.scroll-to');
  $(window).on('scroll', function () {
    this.scrollY > 100 ? header.addClass('bg-primary') : header.removeClass('bg-primary');
  });
  respond.fadeOut();
  $("form").on('submit', function (e) {
    e.preventDefault(); // avoid to execute the actual submit of the form.

    respond.fadeIn();
    var form = $(this);
    var url = form.attr('action');
    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(),
      // serializes the form's elements.
      success: function success(res) {
        if (res.status == 1) {
          respond.html('<div class="row"><div class="col-12"><h6 class="text-secondary"><b>Grazie messaggio inviato</b></h6></div></div>');
        } else {
          respond.html('<div class="row"><div class="col-12"><h6 class="text-secondary"><b>Attenzione! </b>' + res.error + '<br>Riprova tra qualche minuto</h6></div></div>');
        }
      }
    });
  }); // Add smooth scrolling to all links

  scrollTo.on('click', function (event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault(); // Store hash

      var hash = this.hash; // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area

      $('html, body').animate({
        scrollTop: $(hash).offset().top - header.outerHeight()
      }, 700, function () {
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if

  });
});